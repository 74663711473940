import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import GatsbyImage from "gatsby-image"

const FirstLawImages = ({ src, className = "", style, imgStyle }) => {
  const data = useStaticQuery(graphql`
    query {
      fl1: file(relativePath: { eq: "first-law/fl-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      fl2: file(relativePath: { eq: "first-law/fl-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      fl3: file(relativePath: { eq: "first-law/fl-3.png" }) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      fl4: file(relativePath: { eq: "first-law/fl-4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      fl5: file(relativePath: { eq: "first-law/fl-5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      fl6: file(relativePath: { eq: "first-law/fl-6.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      fl7: file(relativePath: { eq: "first-law/fl-7.png" }) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      fl7a: file(relativePath: { eq: "first-law/fl-7a.png" }) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      fl7b: file(relativePath: { eq: "first-law/fl-7b.png" }) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      fl7c: file(relativePath: { eq: "first-law/fl-7c.png" }) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      fl8a: file(relativePath: { eq: "first-law/fl-8a.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      fl8b: file(relativePath: { eq: "first-law/fl-8b.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      fl8c: file(relativePath: { eq: "first-law/fl-8c.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      fl8d: file(relativePath: { eq: "first-law/fl-8d.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      fl8e: file(relativePath: { eq: "first-law/fl-8e.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      fl8f: file(relativePath: { eq: "first-law/fl-8f.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      fl10: file(relativePath: { eq: "first-law/fl-10.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      fl11: file(relativePath: { eq: "first-law/fl-11.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      fl12: file(relativePath: { eq: "first-law/fl-12.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      fl13a: file(relativePath: { eq: "first-law/fl-13a.png" }) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      fl13b: file(relativePath: { eq: "first-law/fl-13b.png" }) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      fl13c: file(relativePath: { eq: "first-law/fl-13c.png" }) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      fl13d: file(relativePath: { eq: "first-law/fl-13d.png" }) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      fl13e: file(relativePath: { eq: "first-law/fl-13e.png" }) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      fl13f: file(relativePath: { eq: "first-law/fl-13f.png" }) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      fl13g: file(relativePath: { eq: "first-law/fl-13g.png" }) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      fl13h: file(relativePath: { eq: "first-law/fl-13h.png" }) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  if (!data[src]) {
    console.error("image not found:", src)
  }
  return (
    <GatsbyImage
      fluid={data[src].childImageSharp.fluid}
      className={`img--${src} ${className}`}
      style={style}
      imgStyle={imgStyle}
    />
  )
}
export default FirstLawImages
