/*
TODO:
ICON ANIMATIONS
CAROUSEL ITEMS
BOOKS CONTAINERS
*/

import React, { useEffect } from "react"

import ProjectAnimations from "../../components/projects/projectAnimations"
import Slide from "../../components/projects/Slide"
import Spacer from "../../components/projects/Spacer"

import LogoSVG from "../../assets/svg/first-law/logo.svg"

import DesktopVideo from "../../assets/images/first-law/fl-9-video.mp4"

import FamilySVG from "../../assets/svg/first-law/familybox.svg"
import HomeSVG from "../../assets/svg/first-law/homebox.svg"
import InsuranceSVG from "../../assets/svg/first-law/insurancebox.svg"
import LightBlueBoxSVG from "../../assets/svg/first-law/lightbluebox.svg"
import RedBoxSVG from "../../assets/svg/first-law/redbox.svg"

import "./template-styles.scss"
import "./first-law.scss"
import Img from "../../assets/images/first-law-images"
import gsap, { toArray } from "gsap/gsap-core"

import SEO from "../../components/seo"
import RightArrowSVG from "../../assets/svg/right-arrow.svg"
import ScrollTrigger from "gsap/ScrollTrigger"
import TransitionLink from "../../components/TransitionLink"
import NavigationOrbs from "../../components/NavigationOrbs"

// const black = "#212931"
const white = "#ffffff"
const darkPurple = "#5856A0"
const lightPurple = "#9A9CC9"
const pink = "#DB3D5F"

const NewTemplate = () => {
  ProjectAnimations()

  useEffect(() => {
    const items = toArray(".item")

    items.forEach((item, index) => {
      gsap.set(item, {
        perspective: "1000px",
        zIndex: index,
        z: 1,
      })
      gsap.set(item.querySelector(".gatsby-image-wrapper,svg"), {
        rotateY: "90deg",
        transformStyle: "preserve-3d",
        opacity: 0,
      })
      const tl = new gsap.timeline({
        scrollTrigger: {
          trigger: item,
          start: "top 50%",
          end: "+=99999",
        },
      })
        .to(item.querySelector(".gatsby-image-wrapper,svg"), {
          opacity: 1,
          duration: 0,
        })
        .to(item.querySelector(".gatsby-image-wrapper,svg"), {
          rotateY: "0deg",
          duration: 0.8,
        })
    })
  }, [])

  useEffect(() => {
    const icons = document.querySelectorAll(".icon")

    const tl = gsap
      .timeline({ repeat: -1 })
      .set(icons, { opacity: 0 })
      .add("start")
    icons.forEach(icon => {
      tl.to(icon, { duration: 0.6, opacity: 1 })
      tl.to(icon, { duration: 0.4, opacity: 0 }, "+=2")
    })
  }, [])

  return (
    <div className="project first-law">
      <SEO
        title="First Law"
        description="A disruptive approach for a progressive law firm that works in ways that put people first. Strategy, name, brand identity, tone of voice, key messaging and content, photography, website, interior fit-out, collateral."
      />
      <NavigationOrbs />

      <Slide className="intro">
        <div className="panel--w-half panel--h-screen panel--image panel--image--full">
          <Img src="fl1" className="h-full" />
        </div>
        <div
          className="panel--w-half panel--h-screen panel--text centered-content"
          style={{ backgroundColor: darkPurple, color: white }}
        >
          <div>
            <h1>First Law</h1>
            <p>
              Working in ways that are better for people is just better
              thinking. First Law offers adaptability and new law technologies
              to help clients get important things done. Adaptability drove the
              look, feel and concept for the brand platform. <br />
              <br />
              Strategy, name, brand identity, tone of voice, key messaging and
              content, photography, website, interior fit-out, collateral.
            </p>
          </div>
        </div>
      </Slide>
      <Slide className="section-1">
        <div
          className="panel--w-half panel--h-screen panel--image panel--image--centered"
          style={{ backgroundColor: darkPurple }}
        >
          <LogoSVG />
        </div>
        <div
          className="panel--w-half panel--h-screen panel--text centered-content"
          style={{ backgroundColor: lightPurple, color: white }}
        >
          <div>
            <p>
              It’s all in the name and the tagline. This is their outlook, so
              it’s how we named them. They’re a far cry from your traditional
              law firm. While thinking-design brought this difference to life,
              typography kept the logo simple.
            </p>
          </div>
        </div>
      </Slide>
      <Slide className="section-2">
        <Spacer />
        <div className="panel--w-half panel--h-screen panel--image panel--image--full">
          <Img src="fl2" className="h-full" />
        </div>
      </Slide>
      <Slide className="section-3">
        <div
          className="panel--w-half panel--h-screen panel--image panel--image--centered"
          style={{ backgroundColor: pink }}
        >
          <div className="icons-container">
            <div className="icon">
              <Img src="fl13a" imgStyle={{ objectFit: "contain" }} />
            </div>
            <div className="icon">
              <Img src="fl13b" imgStyle={{ objectFit: "contain" }} />
            </div>
            <div className="icon">
              <Img src="fl13c" imgStyle={{ objectFit: "contain" }} />
            </div>
            <div className="icon">
              <Img src="fl13d" imgStyle={{ objectFit: "contain" }} />
            </div>
            <div className="icon">
              <Img src="fl13e" imgStyle={{ objectFit: "contain" }} />
            </div>
            <div className="icon">
              <Img src="fl13f" imgStyle={{ objectFit: "contain" }} />
            </div>
            <div className="icon">
              <Img src="fl13g" imgStyle={{ objectFit: "contain" }} />
            </div>
            <div className="icon">
              <Img src="fl13h" imgStyle={{ objectFit: "contain" }} />
            </div>
          </div>
        </div>
        <div
          className="panel--w-half panel--h-screen panel--text centered-content"
          style={{ backgroundColor: white, color: pink }}
        >
          <div>
            <p>
              First Law makes everything easy. This simple icon set makes it
              easy to find what you’re looking for.
            </p>
          </div>
        </div>
      </Slide>
      <div className="section-4 carousel-container section-wrapper">
        <div className="panel--w-full pin-once-scrolled">
          <div
            className="panel--w-half panel--h-screen centered-content "
            style={{ backgroundColor: darkPurple, color: white }}
          >
            <div className="panel--text">
              <p>
                Not every law firm can claim to be able to work around their
                clients’ needs and schedule. Adaptability is their big
                proposition, so we developed a sliding tile concept of squares
                that move around the grid to tell the story.
              </p>
            </div>
          </div>
        </div>

        <div className="items-container-parent pin-for-400 pin-fully-scrolled flex flex-wrap">
          <Spacer />
          <div
            className="items-container panel--w-half "
            style={{ backgroundColor: white }}
          >
            <div className="item">
              <LightBlueBoxSVG />
            </div>
            <div className="item">
              <Img src="fl8c" />
            </div>
            <div className="item">
              <Img src="fl8e" />
            </div>
            <div className="item">
              <FamilySVG />
            </div>
            <div className="item">
              <InsuranceSVG />
            </div>
            <div className="item">
              <LightBlueBoxSVG />
            </div>
            <div className="item">
              <LightBlueBoxSVG />
            </div>
            <div className="item">
              <Img src="fl8f" />
            </div>
            <div className="item">
              <Img src="fl8a" />
            </div>
            <div className="item">
              <HomeSVG />
            </div>
            <div className="item">
              <RedBoxSVG />
            </div>
            <div className="item">
              <Img src="fl8d" />
            </div>
            <div className="item">
              <Img src="fl8b" />
            </div>
            <div className="item">
              <LightBlueBoxSVG />
            </div>
          </div>
        </div>
      </div>
      <Slide className="section-5">
        <div
          className="desktop panel--w-full panel--h-screen screen-container"
          style={{ backgroundColor: white }}
        >
          <div className="screen-container__inner-container">
            <Img src="fl3" className="screen-container__frame" />
            <div className="screen-container__screen">
              <video src={DesktopVideo} autoPlay playsInline muted loop></video>
            </div>
          </div>
        </div>
      </Slide>
      <Slide className="section-6">
        <Spacer />
        <div
          className="panel--w-half panel--h-screen centered-content"
          style={{ backgroundColor: pink, color: white }}
        >
          <div className="panel--text">
            <p>
              A responsive website delivers information and ease. Interactive
              animation creates a positive experience and brings the
              adaptability story to life.
              <br />
              <br />
              Experience the full site{" "}
              <a
                href="https://firstlaw.co.nz/"
                target="_blank"
                rel="noopener noreferrer"
                className="font-medium underline"
              >
                here
              </a>
            </p>
          </div>
        </div>
      </Slide>
      <Slide className="section-7">
        <div className="panel--w-full panel--h-screen panel--image panel--image--full">
          <Img src="fl4" className="h-full" />
        </div>
      </Slide>
      <Slide className="section-8">
        <div className="panel--w-full panel--h-screen panel--image panel--image--full">
          <Img src="fl5" />
        </div>
      </Slide>
      <div className="section-9 flex flex-wrap pin-for-400">
        <Spacer />
        <div
          className="panel--w-half panel--h-screen centered-content "
          style={{ backgroundColor: darkPurple, color: white }}
        >
          <div className="panel--text">
            <p>
              An interior fit out uses design, colour and messaging to bring the
              brand experience into the everyday.
            </p>
          </div>
        </div>
      </div>
      <Spacer />
      <Slide className="section-10">
        <div className="panel--w-full panel--h-screen panel--image panel--image--full">
          <Img src="fl6" className="h-full" />
        </div>
      </Slide>
      <Slide className="section-11">
        <div className="panel--w-full panel--h-screen panel--image panel--image--full">
          <Img src="fl1" className="h-full" />
        </div>
      </Slide>
      <Slide className="section-12">
        <div className="panel--w-half panel--h-screen panel--image panel--image--full">
          <Img src="fl11" />
        </div>
        <div className="panel--w-half panel--h-screen panel--image panel--image--full">
          <Img src="fl10" />
        </div>
      </Slide>
      <div className="section-13 flex flex-wrap pin-for-400">
        <Spacer />
        <div
          className="panel--w-half panel--h-screen centered-content "
          style={{ backgroundColor: darkPurple, color: white }}
        >
          <div className="panel--text">
            <p>
              As well as stationery and collateral, we created an engaging
              client gift. Adaptability and solutions to challenges are at your
              fingertips.
            </p>
          </div>
        </div>
      </div>
      <Spacer />
      <Slide className="section-14">
        <div className="panel--w-full panel--h-screen panel--image panel--image--full">
          <Img src="fl12" />
        </div>
      </Slide>
      {/* <Slide className="section-15">
        <div className="panel--w-full panel--h-screen panel--image panel--image--full bg-white"></div>
      </Slide> */}
      {/* FL SCROLL BOOKS */}
      {/* <div className="w-full ">
        <div className="panel--w-full panel--h-screen panel--image panel--image--full">
          <Img src="fl7" imgStyle={{ objectFit: "contain" }} />
        </div>
      </div> */}
      <div className="bg-white relative">
        <Slide className="section-15" noWrapper={true}>
          <div className="panel--w-full panel--h-screen panel--image centered-content">
            <Img src="fl7" imgStyle={{ objectFit: "contain" }} />
          </div>
        </Slide>
        <Slide className="section-16">
          <div className="panel--w-full panel--h-screen panel--image centered-content">
            <Img src="fl7a" imgStyle={{ objectFit: "contain" }} />
            <Img src="fl7" imgStyle={{ objectFit: "contain" }} />
          </div>
        </Slide>{" "}
        <Slide className="section-17" noWrapper={true}>
          <div className="panel--w-full panel--h-screen panel--image centered-content">
            <Img src="fl7b" imgStyle={{ objectFit: "contain" }} />
            <Img src="fl7" imgStyle={{ objectFit: "contain" }} />
          </div>
        </Slide>{" "}
        <Slide className="section-18" noWrapper={true}>
          <div className="panel--w-full panel--h-screen panel--image centered-content">
            <Img src="fl7c" imgStyle={{ objectFit: "contain" }} />
            <Img src="fl7" imgStyle={{ objectFit: "contain" }} />
          </div>
        </Slide>
        <Slide className="section-19">
          <Spacer />
          <div
            className="panel--w-half panel--h-screen centered-content"
            style={{ backgroundColor: darkPurple, color: white }}
          >
            <div className="panel--text">
              <p>
                With new law technology and a compelling, accessible brand First
                Law are helping people, enabling the market and driving new
                standards for the law industry.
                <br />
                <br />
                <TransitionLink
                  to="/projects/ko-and-co/"
                  color="#d3455f"
                  className="underline"
                >
                  <RightArrowSVG
                    className="inline-block"
                    style={{ width: "0.5em", marginRight: "0.5em" }}
                  />
                  Next project
                </TransitionLink>
                <br />
                <TransitionLink
                  to="/#projects"
                  color="white"
                  className="underline"
                >
                  <RightArrowSVG
                    className="inline-block transform rotate-180"
                    style={{ width: "0.5em", marginRight: "0.5em" }}
                  />
                  Back to all projects
                </TransitionLink>
              </p>
            </div>
          </div>
        </Slide>
      </div>
      {/* FL SCROLL BOOKS */}
      {/* <div className="section-13 flex flex-wrap pin-for-400">
        <Spacer />
        <div
          className="panel--w-half panel--h-screen panel--text centered-content "
          style={{ backgroundColor: darkPurple, color: white }}
        >
          <div>
            <p>
              With new law technology and a compelling, accessible brand First
              Law are helping people, enabling the market and driving new
              standards for the law industry.
            </p>
          </div>
        </div>
      </div> */}
    </div>
  )
}

export default NewTemplate
